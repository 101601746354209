import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

const Logo = ({ fill = "white" }) => {
    return (
        <LogoContainer>
            <img style={{maxWidth: '156px'}} src='https://i.ibb.co/fQ3KkVT/redrock.png'></img>
        </LogoContainer>
    );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    
    .Logo__Mobile{
      display: block;
    }
  `}
`;
